import { ref } from 'vue'

import { useQueryClient } from '@tanstack/vue-query'
import { defineStore } from 'pinia'

import router from '@/router'

export const useUserStore = defineStore(
    'user',
    () => {
        const isAuthenticated = ref(false)
        const accessToken = ref<string>()
        const refreshToken = ref<string>()
        const userID = ref<number>()

        const queryClient = useQueryClient()

        const signOut = async () => {
            isAuthenticated.value = false
            accessToken.value = undefined
            refreshToken.value = undefined
            userID.value = undefined
            await router.push({ name: 'login' })
            queryClient.removeQueries()
        }

        return { isAuthenticated, signOut, accessToken, refreshToken, userID }
    },
    { persist: true }
)
