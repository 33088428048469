import { defineAbility } from '@casl/ability'
import { AxiosError } from 'axios'
import { md5 } from 'js-md5'
import { z, ZodError } from 'zod'

import { axiosPrivate, axiosPublic } from '@/common/network'

export class UnknownError extends Error {}

export namespace SignInErrors {
    export class InvalidCredentials extends Error {}
    export class InvalidData extends Error {}
}

export async function signIn(input: { login: string; password: string }) {
    input.password = md5(input.password)

    const scheme = z.object({
        token: z.string(),
        refreshToken: z.string()
    })
    try {
        const response = await axiosPublic.post('admin/auth', input)
        const data = scheme.parse(response.data)
        return data
    } catch (error: any) {
        if (error instanceof AxiosError) {
            if (error.response?.status === 401) {
                throw SignInErrors.InvalidCredentials
            } else {
                const body = error.response?.data
                throw new UnknownError(body)
            }
        }
        if (error instanceof ZodError) {
            throw SignInErrors.InvalidData
        }
        throw new UnknownError(error)
    }
}

export async function getMe() {
    const scheme = z.object({
        user_id: z.number(),
        name: z.string(),
        surname: z.string(),
        phone: z.string(),
        email: z.string(),
        birthday: z
            .string()
            .nullish()
            .transform((v) => v || undefined),
        role: z
            .union([
                z.literal('admin'),
                z.literal('adminrest'),
                z.literal('marketer'),
                z.literal('techsupport'),
                z.literal('waiter')
            ])
            .transform((role) => {
                const roleDisplayNames: Record<typeof role, string> = {
                    admin: 'Суперадмин',
                    adminrest: 'Администратор',
                    marketer: 'Маркетолог',
                    techsupport: 'Тех. поддержка',
                    waiter: 'Официант'
                }
                return {
                    code: role,
                    name: roleDisplayNames[role]
                }
            })
    })

    try {
        const response = await axiosPrivate.get('admin/me')
        // response.data.role = 'waiter'
        const data = scheme.parse(response.data)
        // const data = scheme.parse({
        //     user_id: 1,
        //     name: 'Адмэн',
        //     surname: 'Марьянович',
        //     phone: '79528366548',
        //     email: 'ayarayarovich.work@gmail.com',
        //     role: 'admin'
        // } satisfies z.input<typeof scheme>)
        return data
    } catch (error: any) {
        if (error instanceof ZodError) {
            throw SignInErrors.InvalidData
        }
        throw new UnknownError(error)
    }
}

export function enforceHisAbilities(role: string) {
    return defineAbility((can, cannot) => {
        switch (role) {
            case 'admin':
                can('manage', 'all')
                break

            case 'adminrest':
                can('read', 'reviews')
                can('reply', 'reviews')
                can('manage', 'dishes')
                cannot('update-status-globally', 'dishes')
                can('read', 'restaurants')
                can('update', 'restaurants')
                can('read', 'stats')
                can('read', 'categories')
                can('manage', 'orders')
                can('manage', 'workers')
                break

            case 'marketer':
                can('read', 'users')
                can('give-bonuses', 'users')
                can('update-status', 'users')
                can('read', 'stats')
                can('read', 'orders')
                break

            case 'techsupport':
                can('read', 'users')
                can('give-bonuses', 'users')
                can('update-status', 'users')
                can('read', 'reviews')
                can('reply', 'reviews')
                break

            case 'waiter':
                can('manage', 'orders')
                break
        }
    })
}
